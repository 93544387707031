document.addEventListener('DOMContentLoaded', () => {
	if (document.querySelector('.mobileNav__list .menu-item-has-children')) {
		let subMenus = document.querySelectorAll(
			'.mobileNav__list .menu-item-has-children'
		);
		subMenus.forEach((subMenu) => {
			let link = subMenu.querySelector('a');
			let span = subMenu.querySelector('span');
			let ul = subMenu.querySelector('ul');
			link.addEventListener('click', (e) => {
				if (subMenu.querySelector('span')) {
					e.preventDefault();
					ul.classList.toggle('sub-menu--open');
					span.classList.toggle('open');
				}
			});
		});
	}
	const hamburger = document.querySelector('.header .hamburger');
	const mobileNav = document.querySelector('.mobileNav');
	hamburger.addEventListener('click', () => {
		hamburger.classList.toggle('hamburger--opened');
		hamburger.setAttribute(
			'aria-expanded',
			hamburger.classList.contains('hamburger--opened')
		);
		mobileNav.classList.toggle('open');
		if (document.querySelector('.mobileNav__list .menu-item-has-children') && !hamburger.classList.contains('hamburger--opened')) {
			let subMenus = document.querySelectorAll(
				'.mobileNav__list .menu-item-has-children'
			);

			subMenus.forEach((subMenu) => {
				let span = subMenu.querySelector('span');
				let ul = subMenu.querySelector('ul');
				ul.classList.remove('sub-menu--open');
				span.classList.remove('open');

			});
		}
	});
	const header = document.querySelector('.header');
	window.addEventListener('scroll', () => {
		document.body.scrollTop > 40 || document.documentElement.scrollTop > 40
			? header.classList.add('header--scrolled')
			: header.classList.remove('header--scrolled');
	});

	const btn = document.querySelector('.scrollTop');

	btn.addEventListener('click', () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	});

	window.onscroll = function () {
		scrollFunction();
	};

	function scrollFunction() {
		if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
			btn.style.display = 'flex';
		} else {
			btn.style.display = 'none';
		}
	}

	if (document.querySelector('.intro')) {
		const intro = new Swiper('.intro .swiper', {
			loop: 1,
			slideperView: 1,
			effect: 'fade',
			speed: 1400,
			autoplay: {
				delay: 5000,
			},
		});
	}

	if (document.querySelector('.opinions')) {
		const opinions = new Swiper('.opinions .swiper', {
			loop: 1,
			slidesPerView: 1,
			spaceBetween: 30,
			speed: 2000,
			autoplay: {
				delay: 3000,
			},
			breakpoints: {
				868: {
					slidesPerView: 2,
					spaceBetween: 50,
				},
				1480: {
					slidesPerView: 2.4,
					spaceBetween: 50,
				},
			},
		});
	}


	const darkGrids = document.querySelectorAll('.darkGridBlock')

	if (darkGrids) {
		darkGrids.forEach(darkGrid => {
			var id = darkGrid.getAttribute('data-block')
			var darkGridSwiper = new Swiper(`[data-block="${id}"] .darkGridBlock__swiper .swiper`,
				{
					slidesPerView: 'auto',
					spaceBetween: 8,
					on: {
						resize: function enableOnlyMobile(swiper) {
							// Disable the slider when the window width is less than or equal to 960
							if (1280 < window.innerWidth) {
								swiper.disable()
							} else {
								swiper.enable()
							}
						},
					}
				}
			)

		});
	}

	const sliderBlocks = document.querySelectorAll('.sliderBlock')

	if (sliderBlocks) {
		sliderBlocks.forEach(slider => {
			var id = slider.getAttribute('data-block')
			var sliderSlider = new Swiper(`[data-block="${id}"] .sliderBlock__slider .swiper`,
				{
					slidesPerView: 'auto',
					spaceBetween: 8,
					navigation: {
						prevEl: `[data-block="${id}"] .sliderBlock__arrows .sliderBlock__prev`,
						nextEl: `[data-block="${id}"] .sliderBlock__arrows .sliderBlock__next`,
					},
					breakpoints: {
						1100: {
							slidesPerView: 3,
						}
					},
					on: {
						resize: function enableOnlyMobile(swiper) {
							// Disable the slider when the window width is less than or equal to 960
							if (1100 < window.innerWidth) {
								if (swiper.slides.length <= 3) {
									swiper.navigation.nextEl.style.display = 'none';
									swiper.disable()
									swiper.navigation.prevEl.style.display = 'none';
								} else {
									swiper.navigation.nextEl.style.display = 'flex';
									swiper.enable()
									swiper.navigation.prevEl.style.display = 'flex';
								}
							} else {
								swiper.enable()
								swiper.navigation.nextEl.style.display = 'flex';
								swiper.navigation.prevEl.style.display = 'flex';
							}
						},
					}
				}
			)

		});
	}
	const testimoniesBlocks = document.querySelectorAll('.testimoniesBlock')

	if (testimoniesBlocks) {
		testimoniesBlocks.forEach(testimonies => {
			var id = testimonies.getAttribute('data-block')
			var testimoniestestimonies = new Swiper(`[data-block="${id}"] .testimoniesBlock__slider .swiper`,
				{
					slidesPerView: 1,
					spaceBetween: 8,
					navigation: {
						prevEl: `[data-block="${id}"] .testimoniesBlock__arrows .testimoniesBlock__prev`,
						nextEl: `[data-block="${id}"] .testimoniesBlock__arrows .testimoniesBlock__next`,
					},
					breakpoints: {
						1100: {
							slidesPerView: 2,
							spaceBetween: 20,
						}
					}
				}
			)

		});
	}





	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);

	window.addEventListener('resize', () => {
		vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	});

	// var questionSets = document.querySelectorAll('.faqBlock__questions');
	// const questionSets2 = document.querySelectorAll('.faq__questions');

	// questionSets = [...questionSets, ...questionSets2];
	// questionSets.forEach((questionSet) => {
	// 	var questions = questionSet.querySelectorAll('.faqQuestion');

	// 	questions.forEach((question) => {
	// 		question.addEventListener('click', () => {

	// 			questions.forEach(q=>{
	// 				var index = parseInt(q.getAttribute('data-index'))
	// 				var newIndex = 0;
	// 				if(q.hasAttribute('open')){
	// 					if(index%2){
	// 						newIndex = index+1;
	// 					}else{
	// 						newIndex = index-1;
	// 					}
	// 					var quest = questionSet.querySelector(`.faqQuestion[data-index="${newIndex}"]`)
	// 					setTimeout(()=>{
	// 						if(!quest.hasAttribute('open')){
	// 							quest.style.height = "fit-content"
	// 						}else{
	// 							quest.style.height = ""
	// 						}
	// 					},450)
	// 				}
	// 			})

	// 		});
	// 	});
	// });

	var faqs = document.querySelectorAll('.faq__questions');
	var blockFaqs = document.querySelectorAll('.faqBlock__questions');
	var blockFaqs2 = document.querySelectorAll('.faqBlock2__questions');
	faqs = [...faqs, ...blockFaqs, ...blockFaqs2];
	faqs.forEach((faq) => {
		var questions = faq.querySelectorAll('.faqQuestion');
		questions.forEach((question) => {
			var index = parseInt(question.getAttribute('data-index'));
			if (index % 2 == 0) {
				var qs = faq.querySelector(`.faqQuestion[data-index="${index - 1}"]`);
				if (qs) {
					if (qs.offsetHeight != question.offsetHeight) {
						if (qs.offsetHeight < question.offsetHeight) {
							var summary = qs.querySelector('summary');
							summary.style.minHeight = question.offsetHeight + 'px';
						} else {
							var summary = question.querySelector('summary')
							summary.style.minHeight = qs.offsetHeight + 'px'
						}
					}
				}
			}
		});
	});


	const banner = document.querySelector('.floatingBanner')
	const footer = document.querySelector('footer')
	console.log(footer.clientWidth)
	document.addEventListener("scroll", () => {
		if (banner && footer && footer.clientWidth < 1700) {
			if (window.scrollY + window.innerHeight > document.documentElement.offsetHeight - footer.clientHeight) {
				banner.style.display = 'none'
			} else {
				banner.style.display = 'block'
			}
		} else {
			banner.style.display = 'block'
		}
	})

});
